export const DEFAULT_COUNTRY_CODE = 'x'
export const DEFAULT_CITY_NAME = 'y'

export function makeListRoute() {
  const { filterConditions } = useFilterConditionsStore()
  const countryCode = filterConditions.selectedCity?.countryCode || DEFAULT_COUNTRY_CODE
  const cityName = filterConditions.selectedCity?.cityEName || DEFAULT_CITY_NAME
    .replace(/\s/g, '-')
    .toLowerCase()
  const { locale } = useLocale()

  return `${countryCode}/${cityName}/${locale.value}.html`
}

export function makeDetailRoute(hotelName: string) {
  hotelName = hotelName.replace(/\s/g, '-').toLowerCase()
  const { filterConditions } = useFilterConditionsStore()
  const countryCode = filterConditions.selectedCity?.countryCode || DEFAULT_COUNTRY_CODE
  const cityName = filterConditions.selectedCity?.cityEName || DEFAULT_CITY_NAME
    .replace(/\s/g, '-')
    .toLowerCase()
  const { locale } = useLocale()

  return `${countryCode}/${cityName}/${hotelName}.${locale.value}.html`
}

export function makeExperienceDetailRoute() {
  const { filterConditions } = useFilterConditionsStore()
  const countryCode = filterConditions.selectedCity?.countryCode || DEFAULT_COUNTRY_CODE
  const cityName = filterConditions.selectedCity?.cityEName || DEFAULT_CITY_NAME
    .replace(/\s/g, '-')
    .toLowerCase()
  const { locale } = useLocale()

  return `${countryCode}/${cityName}/${locale.value}.html`
}

export function navigateToList(options = { replace: false }) {
  const { query } = useRoute()
  const filterConditionStore = useFilterConditionsStore()

  return navigateTo({
    path: `/attractions/list/${makeListRoute()}`,
    query: {
      ...query,
      tab_key: 1,
      query: undefined,
      destinationId: filterConditionStore.filterConditions.selectedCity?.destinationId || 454,
    },
  }, {
    replace: options.replace,
  })
}

export function navigateWithLocale(route: Parameters<typeof navigateTo>[0], options: Parameters<typeof navigateTo>[1] = {}) {
  const { locale } = useLocale()

  return navigateTo(typeof route === 'string'
    ? `/${locale.value}${route}`
    : {
        ...route,
        path: `/${locale.value}${route!.path}`,
      }, options)
}
